import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { Link } from 'gatsby';
import Logo from '../../data/logos/FishermanRedTextLogo.svg';
import Links from './Links';
import './Navigation.css';

export default class MobileNavigation extends React.Component {
  constructor(props) {
    super(props);

    this.state = { open: false };
    this.toggleNavigation = this.toggleNavigation.bind(this);
  }

  toggleNavigation() {
    this.setState(({ open }) => ({ open: !open }));
  }

  render() {
    const { open } = this.state;
    const { showSignUp = true, pathPrefix, additionalLinks } = this.props;
    const filteredLinks = !showSignUp
      ? Links.filter(({ title }) => title !== 'Get Started')
      : Links;

    return (
      <div id={`navigation-inner-container`} className="mobile">
        <div>
          <Icon name="bars" onClick={this.toggleNavigation} />
        </div>
        <div>
          <Link to="/">
            <img src={Logo} alt="Fisherman Logo" id="navigation-logo" />
          </Link>
        </div>
        <div className={`mobile-navigation-links${!open ? ' closed' : ' open'}`}>
          {[...filteredLinks, ...additionalLinks].map(({ title, to, internal, isButton }) => {
            if (internal) {
              return (
                <Link
                  key={title}
                  to={title === 'Get Started' ? `${pathPrefix}${to}` : to}
                  onClick={this.toggleNavigation}
                  activeStyle={{ textDecoration: 'underline' }}
                >
                  {isButton ? <Button primary>{title}</Button> : title}
                </Link>
              );
            } else
              return (
                <a
                  key={title}
                  href={to}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={this.toggleNavigation}
                >
                  {title}
                </a>
              );
          })}
        </div>
      </div>
    );
  }
}
