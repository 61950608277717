import React from 'react';
import './Navigation.css';
import DesktopNavigation from './DesktopNavigation';
import MobileNavigation from './MobileNavigation';

export default class Navigation extends React.Component {
  render() {
    const { showSignUp, pathPrefix, additionalLinks } = this.props;
    return (
      <div id="navigation-container">
        <DesktopNavigation
          showSignUp={showSignUp}
          pathPrefix={pathPrefix}
          additionalLinks={additionalLinks}
        />
        <MobileNavigation
          showSignUp={showSignUp}
          pathPrefix={pathPrefix}
          additionalLinks={additionalLinks}
        />
      </div>
    );
  }
}
