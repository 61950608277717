const SocialMedia = {
  facebook: {
    link: 'https://www.facebook.com/FishermanTechnologies/',
  },
  twitter: {
    link: 'https://twitter.com/FishermanWeb',
  },
  linkedin: {
    link: 'https://www.linkedin.com/company/fishermanwebsites/',
  },
  github: {
    link: 'https://github.com/Fishrmn',
  },
  instagram: {
    link: 'https://www.instagram.com/fishermanwebsites/',
  },
};

export default SocialMedia;
