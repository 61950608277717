const Links = [
  {
    title: 'Login',
    to: `${process.env.FISHERMAN_DASHBOARD_PATH}/login`,
    internal: false,
    isButton: false,
  },
];

export default Links;
