import React from 'react';

class ChatlioWidget extends React.Component {
  static addWidget() {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.innerHTML =
      'window._chatlio = window._chatlio||[]; !function(){ var t=document.getElementById("chatlio-widget-embed");if(t&&window.ChatlioReact&&_chatlio.init)return void _chatlio.init(t,ChatlioReact);for(var e=function(t){return function(){_chatlio.push([t].concat(arguments)) }},i=["configure","identify","track","show","hide","isShown","isOnline", "page", "open", "showOrHide"],a=0;a<i.length;a++)_chatlio[i[a]]||(_chatlio[i[a]]=e(i[a]));var n=document.createElement("script"),c=document.getElementsByTagName("script")[0];n.id="chatlio-widget-embed",n.src="https://w.chatlio.com/w.chatlio-widget.js",n.async=!0,n.setAttribute("data-embed-version","2.3"); n.setAttribute(\'data-widget-id\',\'ad39c9d9-be73-45b1-7390-943a7f80e108\'); c.parentNode.insertBefore(n,c); }();';
    script.async = true;
    document.body.appendChild(script);
  }

  static configureChatlio() {
    // https://chatlio.com/docs/api-v1/#api-configure
    if (window._chatlio) {
      window._chatlio.configure({
        requireInfo: true,
        requireInfoGreeting: 'Enter your name and email to start chatting!',
        teamAvatarOn: true,
        showConversationRating: false,
      });
    }
  }

  componentDidMount() {
    const activeEnv = process.env.GATSBY_ACTIVE_ENV || process.env.NODE_ENV || 'development';

    if (!window._chatlio && (activeEnv === 'production' || activeEnv === 'staging')) {
      ChatlioWidget.addWidget();
    }
    ChatlioWidget.configureChatlio();
  }

  render() {
    return null;
  }
}

export default ChatlioWidget;
