import React from 'react';
import 'semantic-ui-less/semantic.less';
import LogRocket from 'logrocket';
import URLParser from 'url';

import ChatlioWidget from '../components/ChatBot/ChatlioWidget';
import Navigation from '../components/Navigation';
import Footer from '../components/Footer';

import '../css/styles.css';
import './SiteLayout.css';
import RecaptchaProvider from '../components/RecaptchaProvider';

if (process.env.NODE_ENV === 'production' && process.env.GATSBY_ACTIVE_ENV !== 'testing') {
  LogRocket.init(process.env.LOGROCKET_ID);
}

export default class DefaultLayout extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = { pathPrefix: '' };
  }

  componentDidMount() {
    if (typeof window !== 'undefined') {
      const url = URLParser.parse(window.location.href);
      const { path = '' } = url;

      if (path.includes('shift4')) this.setState({ pathPrefix: 'shift4' });
    }
  }

  render() {
    const { pathPrefix } = this.state;
    const { additionalLinks, children } = this.props;

    return (
      <div style={{ overflow: 'hidden' }}>
        <Navigation
          pathPrefix={pathPrefix}
          showSignUp={pathPrefix.length > 0}
          additionalLinks={additionalLinks || []}
        />
        <RecaptchaProvider>
          <div id="page-container">{children}</div>
          <Footer />
        </RecaptchaProvider>
        <ChatlioWidget />
      </div>
    );
  }
}
