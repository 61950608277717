import React from 'react';

import { Button, Grid } from 'semantic-ui-react';

import { Link } from 'gatsby';

import SocialMedia from './SocialMedia';

import FishermanLogo from '../../data/logos/FishermanWhiteIconLogo.png';

import './Footer.css';

export default class Footer extends React.PureComponent {
  render() {
    const currentYear = new Date().getFullYear();
    return (
      <Grid id="footer" columns={3} verticalAlign="top" stackable>
        <Grid.Column width={4} />
        <Grid.Column id="footer-main" width={8}>
          <div id="footer-sm">
            {Object.keys(SocialMedia).map((key) => (
              <a href={SocialMedia[key].link} key={key} target="_blank" rel="noopener noreferrer">
                <Button circular icon={key} size="huge" />
              </a>
            ))}
          </div>
          <img id="footer-logo" src={FishermanLogo} alt="Fisherman Logo" />
          <div id="footer-site-map" className="footer-misc-info">
            <div id="footer-company-name">Fisherman Technologies Inc. {currentYear}</div>
            <span>•</span>
            <Link to="/privacy_policy" href="/privacy_policy" target="_blank" rel="noopener">
              Privacy Policy
            </Link>
            <span>•</span>
            <Link to="/partners/" href="/partners/">
              Partners
            </Link>
          </div>
        </Grid.Column>
        <Grid.Column id="footer-subscription" width={4} />
      </Grid>
    );
  }
}
